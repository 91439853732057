
import {Component, Vue, Prop} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import VModel from "@/mixins/VModel.vue";
import {JobService, ROUTES,CandidateStatus} from "@/data";
import {Getter,Action} from "vuex-class";
import {LabelValue, StoreAction} from "@/types";
import SelectAutocomplete from "@/components/SelectAutocomplete.vue";

@Component({
    name: "FilterBar",
    components: {SelectAutocomplete}
})
export default class FilterBar extends mixins(VModel) {
    @Prop() mode!: string;
    companies : any = [];
    jobOffers: any = [];

    @Getter('jobOptions') jobOptions!: LabelValue[];
    @Getter('applicationOrigin') applicationOrigin!: LabelValue[];
    @Action('actions/loadCompanies') loadCompanies!: StoreAction;
    @Action('actions/loadJobOffers') loadJobOffers!: StoreAction;

    get isFollowing() {
        return this.mode === "following"
            || !this.mode && (this.$route.name === ROUTES.APP.ATS.FOLLOWING._ROOT
            || this.$route.name === ROUTES.APP.ATS.FOLLOWING.PLANNING);
    }

    get isJobOfferList() {
        return this.mode === "jobOffer"
            ||  !this.mode && (this.$route.name === ROUTES.APP.ATS.JOBOFFER.LIST
            || this.$route.name === ROUTES.APP.ATS.JOBOFFER.OLD);
    }

    get isCandidatesList() {
        return this.mode === "candidate"
            ||  !this.mode && this.$route.name === ROUTES.APP.ATS.CV.LIST;
    }

    get isJobOfferCandidatesList() {
        return this.mode === "jobOfferCandidate"
            ||  !this.mode && this.$route.name === ROUTES.APP.ATS.JOBOFFER.READ.KANBAN;
    }

    get isJobOfferFollowing() {
        return this.mode === "jobOfferFollowing"
            ||  !this.mode && this.$route.name === ROUTES.APP.ATS.JOBOFFER.READ.KANBAN;
    }

    get jobOfferOptions() {
        return this.jobOffers.jobOffers && this.jobOffers.jobOffers.map((jobOffer:any) =>{
            return {
                label: jobOffer.name,
                value: jobOffer._id
            };
        }) || [];
    }

    get sourceOptions() {
        return Object.values(JobService).map(service => {
            return {
                label: this.$t('page.jobOffer.services.' + service + '.title'),
                value: service
            };
        });
    }
    get statusOptions() {
        return Object.values(CandidateStatus).map(status => {
            return {
                label:this.$t('page.mission.jobOffer.'+ status),
                value: status
            };
        });
    }

    get clientOptions() {
        return this.companies.rows && this.companies.rows.map((company:any) =>{
            const label = company.name || '';
            const value = company._id || '';
            const slug = company.nameSlug || '';
            return { label, value, slug };
        }) || [];
    }

    get fields() {
        const search:any = {
            property: 'search',
            labelIcon: this.$t('atsFilters.search'),
            icon: 'search',
            placeholder: "Recherche",
        };
        const jobOffer:any = {
            property: 'jobOfferId',
            label: this.$t('atsFilters.jobOffer'),
            type: "select",
            options: [
                {
                    label: this.$t('atsFilters.jobOffer'),
                    value: ""
                },
                ...this.jobOfferOptions]
        };
        const source:any = {
            property: 'source',
            label: this.$t('atsFilters.source'),
            type: "select",
            options: [
                {
                    label: this.$t('atsFilters.source'),
                    value: ""
                },
                ...this.sourceOptions]
        };
        const status:any = {
            property: 'status',
            label: this.$t('page.mission.status'),
            type: "select",
            options: [
                {
                    label: this.$t('page.mission.status'),
                    value: ""
                },
                ...this.statusOptions]
        };
        const job:any = {
            name: this.$t('atsFilters.job'),
            property: 'jobId',
            label: this.$t('atsFilters.job'),
            type: "autocomplete",
            placeholder: this.$t('atsFilters.search'),
            onChange: this.onChange,
            options: this.jobOptions,
            defaultValue: null,
        };
        const client:any = {
            name: this.$t('atsFilters.client'),
            property: 'clientId',
            label: this.$t('atsFilters.client'),
            type: "autocomplete",
            placeholder: this.$t('atsFilters.search'),
            onChange: this.onChange,
            options: this.clientOptions,
            defaultValue: null
        };
        const origin:any = {
            property: 'origin',
            label: "Toutes les origines",
            type: "select",
            options: [
                { label: "Toutes les origines", value: "" },

            ].concat( this.applicationOrigin )
        }

        if (this.isFollowing) {
            return [
                search,
                // jobOffer,
                origin,
                job,
                client,
            ];
        }
        if (this.isJobOfferList) {
            return [
                search,
                client,
                job,
                source,
            ];
        }
        if (this.isCandidatesList) {
            return [
                search,
                // jobOffer,
                origin,
                job,
                status,
            ];
        }
        if (this.isJobOfferCandidatesList) {
            return [
                search,
                source,
            ];
        }
        if (this.isJobOfferFollowing) {
            return [
                search,
                source,
                job,
                client,
            ];
        }
    }

    isSelected(property:string) {
        return this.innerValue[property] && (this.innerValue[property].value || this.innerValue[property].length);
    }

    onChange(data:any, propertyName:string) {
        this.innerValue[propertyName] = data && data[0] && data[0].value;
    }

    async init() {
        try {
            this.jobOffers = await this.loadJobOffers();
            this.companies = await this.loadCompanies();
        }
        catch (e){
            console.error(e);
        }
    }

    beforeMount(){
        this.init();
    }
}
