
import { Component, Vue, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import SelfInitLayout from "@/mixins/SelfInitLayout.vue";
import FilterBar from "@/components/ats/FilterBar.vue";
import { ROUTES } from "@/data";
import TitleManager from "@/mixins/TitleManager.vue";
import NewIcon from "@/components/NewIcon.vue";
import ActionButton from "@/components/ActionButton.vue";
import { showModal } from "@/helpers/callables";
import WorkerAddVerificationModal from "@/components/modal/WorkerAddVerificationModal.vue";

@Component({
    name: "FollowingLayout",
    components: { FilterBar, ActionButton, NewIcon, WorkerAddVerificationModal },
})
export default class FollowingLayout extends mixins(SelfInitLayout, TitleManager, NewIcon, ActionButton) {
    filters: any = {
        search: "",
        jobOfferId: "",
        source: "",
        jobId: "",
        clientId: "",
    };
    displayFilterBar = true;
    ROUTES = ROUTES;
    showWorkerAddVerificationModal = false;
    showAddCandidateButton = false;

    showHideDisplayBar(action: boolean) {
        this.displayFilterBar = action;
    }
    showHideAddCandidateButton(action: boolean) {
        this.showAddCandidateButton = action;
    }

    get jobOfferId() {
        return this.$route.params.jobOfferId;
    }

    get isJobOffer() {
        return this.$route.name === ROUTES.APP.ATS.JOBOFFER.READ.KANBAN;
    }
    get isNewCandidate() {
        return this.$route.name === ROUTES.APP.ATS.CV.NEWCANDIDAT;
    }

    toggleAddInterimVerificationModal() {
        this.showWorkerAddVerificationModal = !this.showWorkerAddVerificationModal;
        if (this.showWorkerAddVerificationModal) showModal.call(this, "WorkerAddVerificationModal");
        else this.$modal.hide("WorkerAddVerificationModal");
    }

    beforeMount() {
        if (this.isJobOffer && this.jobOfferId) {
            this.filters.jobOfferId = this.jobOfferId;
        }
    }
}
